import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  NavigationHeaderContainer,
  ToggleButton,
  ToggleStatus,
  ToggleHandler,
  ProfileImageContainer,
  ImageContainer,
  UserLoadingContainer,
} from "@c/users-view/MainContent.styled";
import {
  ArrowLeft,
  CameraIconContainer,
  EditButton,
  LastLoginLabel,
  ParentLabelMainContainer,
  ParentNameContainer,
  ParentProfileHeaderLeft,
  ToggleButtonMainContainer,
  UserName,
} from "@c/users-view/parent-profile/ParentProfile.styled";
import { module as user } from "@st/user";
import { connectStore } from "redux-box";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
import { module as ui } from "@st/ui";


class ChildProfileHeader extends Component {
  constructor() {
    super();
    this.state = {
      setUsername: true,
      setGrade: true,
      showInput: false,
      child_info: {
        name: "",
        id: "",
      },
      grade: "",
      // grade_level: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.active_child) {
      this.normalizeForState(nextProps.user.active_child);
    } else {
      this.setState({
        child_info: {
          ...this.state.child_info,
          id: "",
          name: "",
          grade: "",
          // grade_level: "",
        },
      });
    }
  }

  normalizeForState = (childInfo) => {
    // console.log(childInfo)
    this.setState({
      child_info: {
        ...this.state.child_info,

        name: childInfo.name,
        id: childInfo.id,
        grade: childInfo.grade.name,
        // grade_level: childInfo.grade_level,
      },
    });
    // console.log(" x1", this.state.grade_level);
  };

  enableSetUserName = (e) => {
    this.setState({
      child_info: {
        ...this.state.child_info,
        name: e.target.value,
      },
    });
    document.getElementById("username").removeAttribute("disabled");
    document.getElementById("username").focus();
  };
  enableSetGrade = (e) => {
         this.props.ui.showModal("updateGradeSection");
  };

  updateUsername = (evt) => {
    if (evt.key === "Enter") {
      this.setState({
        setUsername: !this.state.setUsername,
        child_info: {
          ...this.state.child_info,
          name: evt.target.value,
        },
      });
      if (this.props.user.active_child) {
        this.props.user.requestUpdateChildProfile(this.state.child_info);
      }
    }
  };
  updateGrade = (evt) => {
    // if (evt.key === "Enter") {
    //   this.setState({
    //     setGrade: !this.state.setGrade,
    //     grade: {
    //       ...this.state.grade,
    //       name: evt.target.value,
    //     },
    //   });
    //   if (this.props.user.active_child) {
    //     this.props.user.requestUpdateChildProfile(this.state.grade);
    //   }
    // }
  };

  handleToggle = (e) => {
    const {permission} =this.props.onboard
    if(Permission.getPermission(permission, "change_user")){
      if (this.props.user && this.props.user.active_child) {
        this.props.user.requestUpdateChildProfile({
          id: this.props.user.active_child.id,
          is_active: !this.props.user.active_child.is_active,
        });
      }
    }

  };

  changeUserName = (e) => {
    this.setState({
      child_info: { ...this.state.child_info, name: e.target.value },
    });
  };
  changeGrade = (e) => {
    this.setState({
      child_info: {
        ...this.state.child_info,
        grade: e.target.value,
        // grade_level: e.target.value,
      },
    });
    // console.log("grade change", this.state.child_info);
  };
  render() {
    let state = this.state;
    // console.log("state", this.state);
    const {permission} =this.props.onboard
    let { user } = this.props;
    return (
      <NavigationHeaderContainer>
        {user.active_child ? (
          <ParentProfileHeaderLeft>
            <Link
              to={
                this.props.user.active_parent
                  ? "/users/parents/" + user.active_child.parent.id
                  : "/users/children"
              }
            >
              <ArrowLeft
                src={require("static/img/arrow_left.png")}
                alt="arrow left"
              />
            </Link>
            {!state.child_info.image || state.child_info.image === "" ? (
              <ProfileImageContainer>
                <CameraIconContainer>
                  <img
                    src={require("static/img/camera_icon.png")}
                    alt="camera icon"
                  />
                </CameraIconContainer>
              </ProfileImageContainer>
            ) : (
              <ProfileImageContainer>
                <ImageContainer
                  src={
                    state.child_info.image
                      ? state.child_info.image
                      : require("static/img/default-image.png")
                  }
                ></ImageContainer>
              </ProfileImageContainer>
            )}

            <ParentLabelMainContainer>
              <ParentNameContainer>
                <UserName
                  width={(user.active_child.name.length + 1) * 12 + "px"}
                  id="username"
                  type="text"
                  placeholder="Enter name"
                  value={this.state.child_info.name}
                  disabled={state.setUsername}
                  autoFocus
                  onChange={this.changeUserName}
                  onKeyDown={this.updateUsername}
                ></UserName>
                {Permission.getPermission(permission, "change_user") ? (
                  <EditButton
                    src={require("static/img/editicon.png")}
                    onClick={this.enableSetUserName}
                  />
                ) : null}
              </ParentNameContainer>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                  gap: "6px",
                }}
              >
                {Permission.getPermission(permission, "change_user") ? (
                  <EditButton
                    src={require("static/img/editicon.png")}
                    onClick={this.enableSetGrade}
                    style={{ marginTop: 0 }}
                  />
                ) : null}
                {this.state.showInput ? (
                  <input
                    id="grade"
                    type="text"
                    placeholder="Enter grade"
                    value={this.state.child_info.grade}
                    // disabled={state.setUsername}
                    autoFocus
                    onChange={this.changeGrade}
                    onKeyDown={this.updateGrade}
                  />
                ) : (
                  <LastLoginLabel
                    width={(user.active_child.name.length + 1) * 12 + "px"}
                    id="grade"
                    type="text"
                    placeholder="Enter name"
                    value={user.active_child.grade.name}
                    // disabled={state.setUsername}
                    autoFocus
                    onChange={this.changeGrade}
                    onKeyDown={this.updateGrade}
                  >
                    Grade :{" "}
                    {user.active_child.grade
                      ? user.active_child.grade.name
                      : "Grade not added"}{" "}
                    | &nbsp;
                    {user.active_child.grade_level.charAt(0).toUpperCase() +
                      user.active_child.grade_level.slice(1)}{" "}
                    &nbsp; (
                    {user.active_child.level.charAt(0).toUpperCase() +
                      user.active_child.level.slice(1)}
                    )
                    | &nbsp; (
                    {user.active_child.school_class.map((sc)=>sc.name).join(', ')}
                    )
                  </LastLoginLabel>
                )}
              </div>
              {/* {Permission.getPermission(permission, "change_user") ? (
                <EditButton
                  src={require("static/img/editicon.png")}
                  onClick={this.enableSetGrade}
                />
              ) : null}
              {this.state.showInput ? (
                <input
                  id="grade"
                  type="text"
                  placeholder="Enter grade"
                  value={this.state.child_info.grade}
                  // disabled={state.setUsername}
                  autoFocus
                  onChange={this.changeGrade}
                  onKeyDown={this.updateGrade}
                />
              ) : (
                <LastLoginLabel
                  width={(user.active_child.name.length + 1) * 12 + "px"}
                  id="grade"
                  type="text"
                  placeholder="Enter name"
                  value={user.active_child.grade.name}
                  // disabled={state.setUsername}
                  autoFocus
                  onChange={this.changeGrade}
                  onKeyDown={this.updateGrade}
                >
                  Grade :{" "}
                  {user.active_child.grade
                    ? user.active_child.grade.name
                    : "Grade not added"}{" "}
                  | &nbsp;
                  {user.active_child.grade_level.charAt(0).toUpperCase() +
                    user.active_child.grade_level.slice(1)}{" "}
                  &nbsp; (
                  {user.active_child.level.charAt(0).toUpperCase() +
                    user.active_child.level.slice(1)}
                  )
                </LastLoginLabel>
              )} */}
            </ParentLabelMainContainer>
          </ParentProfileHeaderLeft>
        ) : null}
        {user.active_child === null ? (
          <UserLoadingContainer>
            <div>
              <img
                alt="loading"
                width="40"
                height="40"
                src={require("static/img/Theme-loader.gif")}
              />
            </div>
          </UserLoadingContainer>
        ) : null}
        {user.active_child ? (
          <ToggleButtonMainContainer>
            {!user.active_child.is_active ? (
              <ToggleStatus onClick={this.handleToggle}>
                {" "}
                Disabled{" "}
              </ToggleStatus>
            ) : (
              <ToggleStatus primary onClick={this.handleToggle}>
                {" "}
                Disable{" "}
              </ToggleStatus>
            )}
            <ToggleButton
              color={user.active_child.is_active ? "#ff8f26" : "#d5d5d5"}
            >
              {!user.active_child.is_active ? (
                <ToggleHandler primary onClick={this.handleToggle} />
              ) : (
                <ToggleHandler onClick={this.handleToggle} />
              )}
            </ToggleButton>
            {!user.active_child.is_active ? (
              <ToggleStatus primary onClick={this.handleToggle}>
                {" "}
                Enable{" "}
              </ToggleStatus>
            ) : (
              <ToggleStatus onClick={this.handleToggle}> Enabled </ToggleStatus>
            )}
          </ToggleButtonMainContainer>
        ) : null}
      </NavigationHeaderContainer>
    );
  }
}

export default connectStore({
  user,onboard,ui
})(ChildProfileHeader);
